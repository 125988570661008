<!-- eslint-disable no-console -->
<template>
  <div>
    <MainCard v-if="!isLoadingTable">
      <template v-slot:body>
        <DataTablePagination
          :total-items="totalItems"
          :headers="headers"
          :items="eSarpras"
          :page="page"
          :search="search"
          :total-pages.sync="totalPages"
          :is-loading="isLoadingData"
          :is-loading-button="isLoadingButton"
          title="Sarana Prasarana"
          subtitle="Halaman untuk menambahkan sarpras "
          add-btn="Tambah Sarpras"
          @delete-item="confirmDestroy"
          @edit-item="showFormEdit"
          @add-item="showFormAdd"
          @change-page="paginationHandler"
          @live-search="searchHandler"
          @show-image="showImage"
        >
        </DataTablePagination>
      </template>
    </MainCard>
    <SkeletonLoaderTable v-else></SkeletonLoaderTable>
    <ModalDialog
      :visible="modalDialog"
      :width="width"
      :is-loading-button="isLoadingButton"
      :dialog="dialog"
      :header="dialog === 'add' ? 'Tambah Sarana Prasarana': 'Edit Sarana Prasarana'"
      :text-button="dialog === 'add' ? 'Tambah': 'Update'"
      @handler-button="handlerButton"
      @close-modal="modalDialog = false"
      @close-button="modalDialog = false"
    >
      <template
        v-slot:body
      >
        <v-form ref="form">
          <v-row class="py-5 px-5">
            <v-col
              xl="6"
              lg="6"
              md="6"
              sm="6"
              cols="6"
            >
              <div>
                <v-autocomplete
                  v-model="sarpras.type"
                  :items="types"
                  label="Jenis"
                  dense
                  clearable
                  hide-details="none"
                  outlined
                ></v-autocomplete>
              </div>
            </v-col>
            <v-col
              xl="6"
              lg="6"
              md="6"
              sm="6"
              cols="6"
            >
              <div>
                <v-text-field
                  v-model="sarpras.name"
                  label="Nama Sarpras"
                  dense
                  value
                  outlined
                ></v-text-field>
              </div>
            </v-col>
            <v-col
              xl="6"
              lg="6"
              md="6"
              sm="12"
              cols="12"
            >
              <div>
                <FileInput
                  :value="initialFile"
                  label="Foto Sarpras"
                  outlined
                  :prependicon="icons.mdiImageMultipleOutline"
                  dense
                  @input="getFile"
                ></FileInput>
              </div>
            </v-col>
            <v-col
              xl="6"
              lg="6"
              md="6"
              sm="12"
              cols="12"
            >
              <div>
                <v-autocomplete
                  v-model="sarpras.condition"
                  :items="conditions"
                  label="Kondisi"
                  dense
                  clearable
                  hide-details="none"
                  outlined
                ></v-autocomplete>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </template>
    </ModalDialog>
    <ConfirmDialog
      :visible="confirmDialog"
      :is-loading-button="isLoadingButton"
      header="Hapus Sarpras"
      @close-button="confirmDialog = false"
      @destroy-button="destroy"
    ></ConfirmDialog>
    <ImageOverlay
      :visible="modalDialogImage"
      :width="800"
      @close="modalDialogImage = false"
    >
      <template v-slot:body>
        <v-card>
          <v-btn
            class="close-image"
            color="white"
            icon
            @click="modalDialogImage = false"
          >
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
          <v-img
            :src="sarpras.photo"
            transition="scroll-y-transition"
            min-width="100"
            max-width="700"
          ></v-img>
        </v-card>
      </template>
    </ImageOverlay>
  </div>
</template>

<script>
import ConfirmDialog from '@/views/components/ConfirmDialog.vue'
import DataTablePagination from '@/views/components/DataTablePagination.vue'
import FileInput from '@/views/components/FileInput.vue'
import ImageOverlay from '@/views/components/ImageOverlay.vue'
import MainCard from '@/views/components/MainCard.vue'
import ModalDialog from '@/views/components/ModalDialog.vue'
import SkeletonLoaderTable from '@/views/components/SkeletonLoaderTable.vue'
import { integerValidator, required } from '@core/utils/validation'

// eslint-disable-next-line object-curly-newline
import {
  mdiClose,
  mdiDeleteOutline,
  mdiDownloadBoxOutline,
  mdiEyeOffOutline,
  mdiEyeOutline,
  mdiFileImportOutline,
  mdiImageMultipleOutline,
  mdiMagnify,
  mdiPencilOutline,
  mdiPlus,
} from '@mdi/js'

export default {
  name: 'Sarpras',
  components: {
    // MandatoryData,
    // DataStudent,
    // DataParent,
    // DataGuardian,
    DataTablePagination,
    SkeletonLoaderTable,
    MainCard,
    ImageOverlay,
    ModalDialog,
    ConfirmDialog,
    FileInput,
  },
  data() {
    return {
      error: false,
      hint: '',
      rules: {
        alreadyTaken: () => 'The username has already been taken.',
      },
      show: false,
      dialog: '',
      validation: {
        required,
        integerValidator,
      },
      types: [
        { text: 'Tanah', value: 'land' },
        { text: 'Bangunan', value: 'building' },
        { text: 'Ruangan', value: 'room' },
      ],
      typesArray: {
        land: 'Tanah',
        building: 'Bangunan',
        room: 'Ruangan',
      },
      conditions: [
        { text: 'Baik', value: 'baik' },
        { text: 'Rusak Ringan: 1% - 30%', value: 'rusak ringan' },
        { text: 'Rusak Sedang: 31% - 50%', value: 'rusak sedang' },
        { text: 'Rusak Berat: 50% - 100%', value: 'rusak berat' },
      ],
      conditionsArray: {
        baik: 'Baik',
        'rusak ringan': 'Rusak Ringan: 1% - 30%',
        'rusak sedang': 'Rusak Sedang: 31% - 50%',
        'rusak berat': 'Rusak Berat: 50% - 100%',
      },
      search: '',
      modalDialog: false,
      modalDialogImage: false,
      confirmDialog: false,
      isLoadingTable: true,
      isLoadingData: false,
      isLoadingButton: false,
      imageOverlay: false,
      width: 1000,
      page: 1,
      totalPages: 0,
      totalItems: 0,
      initialSarpras: {
        type: '',
        name: '',
        photo: '',
        condition: '',
      },
      sarpras: {},
      classes: [],
      eSarpras: [],
      formValid: false,
      headers: [
        { text: 'NO', value: 'index' },
        { text: 'Jenis', value: 'sarprasType' },
        { text: 'Nama Sarpras', value: 'name' },
        { text: 'Kondisi', value: 'sarprasCondition' },
        { text: 'Foto', value: 'photo' },
        { text: 'Alat', value: 'detail-tool' },
        { text: 'ACTIONS', value: 'actions' },
        { text: '', value: 'rehab-sarpras' },
      ],
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiPlus,
        mdiImageMultipleOutline,
        mdiFileImportOutline,
        mdiDownloadBoxOutline,
        mdiClose,
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      gender: '',
      genders: [
        { index: 1, value: 'Laki-Laki' },
        { index: 2, value: 'Perempuan' },
      ],
      formData: [],
      exportData: true,
      importExport: true,
      sarprasFilter: true,
      modalDialogImport: false,
      importFile: null,
      filterQuery: {
        search: '',
      },
      valueCheck: false,
    }
  },
  computed: {
    initialFile: {
      get() {
        if (typeof this.sarpras.photo === 'string') {
          return new File([this.sarpras.photo], this.sarpras.photo, { type: 'text/plain' })
        }

        return this.sarpras.photo
      },
      set() {
        return this.sarpras.photo
      },
    },
  },
  watch: {
    page: {
      handler() {
        this.listSarpras(this.filterQuery)
      },
    },
  },
  mounted() {
    this.getSchoolUuid()
    this.listSarpras()
  },
  methods: {
    changeValid() {
      this.valueCheck = this.$refs.form.$data.formValid
    },

    getFile(data) {
      this.sarpras.photo = data
    },
    getSchoolUuid() {
      this.school = JSON.parse(localStorage.getItem('user')).user.details
      this.school.forEach(el => {
        if (el.school === undefined) {
          this.school_uuid = el.uuid

          return
        }

        if (el.school === null) {
          return
        }
        this.user_uuid = JSON.parse(localStorage.getItem('user')).user.uuid
        this.school_uuid = el.school.uuid
      })

      return true
    },

    async listSarpras(params = {}) {
      this.isLoadingData = true
      await this.$services.ApiServices.list('sarpras', {
        ...params,
        school_uuid: this.school_uuid,
        page: this.page,
      }).then(
        ({ data }) => {
          this.eSarpras = data.data.map((sarpras, index) => ({
            ...sarpras,
            sarprasType: this.typesArray[sarpras.type],
            sarprasCondition: this.conditionsArray[sarpras.condition],
            index: index + 1,
          }))
          this.totalItems = data.meta.total
          this.totalPages = data.meta.last_page
        },
        err => console.error(err),
      )
      this.isLoadingTable = false
      this.isLoadingData = false
    },
    showFormAdd() {
      this.dialog = 'add'
      this.resetSarpras()
      this.editUuid = ''
      this.modalDialog = true
    },
    async add() {
      this.dialog = 'add'
      this.isLoadingButton = true
      this.formData = new FormData()
      this.$data.sarpras.school_uuid = this.school_uuid
      Object.entries(this.$data.sarpras).forEach(([key, item]) => {
        this.formData.append(key, item)
      })
      await this.$services.ApiServices.add('sarpras', this.formData).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.modalDialog = false
          this.resetSarpras()
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )

      await this.listSarpras(this.filterQuery)
    },

    async showFormEdit(uuid) {
      this.dialog = 'edit'
      this.modalDialog = true
      await this.$services.ApiServices.get('sarpras', uuid).then(
        ({ data }) => {
          this.sarpras = data.data
          this.sarpras.uuid = data.data.uuid
        },
        err => console.error(err),
      )
    },
    async update() {
      this.isLoadingButton = true
      this.formData = new FormData()
      this.$data.sarpras.school_uuid = this.school_uuid
      Object.entries(this.$data.sarpras).forEach(([key, item]) => {
        this.formData.append(key, item)
      })
      const oldPhoto = this.$data.sarpras.photo
      if (typeof oldPhoto && typeof this.formData.get('photo') === 'string') {
        this.formData.delete('photo')
      }
      await this.$services.ApiServices.update('sarpras', this.formData, this.sarpras.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.modalDialog = false
          this.resetSarpras()
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      await this.listSarpras(this.filterQuery)
    },
    resetSarpras() {
      this.$refs.form.reset()
      // this.sarpras = this.initialSarpras
    },
    async get() {
      this.error = false
      await this.$services.ApiServices.get('sarpras', this.editUuid).then(
        ({ data }) => {
          this.sarpras = { ...data.data }
          this.sarpras.type = data.data.type
          this.sarpras.name = data.data.name
          this.sarpras.photo = data.data.photo
          this.sarpras.condition = data.data.condition
        },
        err => console.error(err),
      )
    },

    handlerButton() {
      if (this.dialog === 'add') this.add()
      else this.update()
    },
    confirmDestroy(uuid) {
      this.sarpras.uuid = uuid
      this.confirmDialog = true
    },
    async destroy() {
      this.isLoadingButton = true
      await this.$services.ApiServices.destroy('sarpras', this.sarpras.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'error',
          })
          // this.destroyTool(this.sarpras.uuid)
        },
        err => console.error(err),
      )
      await this.listSarpras(this.filterQuery)
      this.isLoadingButton = false
      this.confirmDialog = false
      this.resetSarpras()
    },
    // async destroyTool(uuid, params = {}) {
    //   this.isLoadingButton = true
    //   await this.$services.ApiServices.destroy('sarprastool', uuid).then(
    //     ({ data }) => data.result.message,
    //     err => console.error(err),
    //   )
    // },
    searchHandler(search) {
      console.log(search)
      this.filterQuery.search = search
      this.page = 1
      this.listSarpras(this.filterQuery)
    },

    paginationHandler(pageNumber) {
      this.page = pageNumber
    },
    async showImage(uuid) {
      await this.$services.ApiServices.get('sarpras', uuid).then(
        ({ data }) => {
          this.sarpras = data.data
          this.sarpras.photo = data.data.photo
        },
        err => console.error(err),
      )
      this.modalDialogImage = true
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
  },
}
</script>

<style scoped>
.autocompleteHidden::v-deep > .v-input__control > .v-text-field__details {
  display: none !important;
}
</style>
